/* @import '~antd/dist/antd.css'; */
html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;
}
